const styles = {
  readMoreLess: 'Show Read More',
  plainText: 'Plain Text',
  twoColumns: {
    top: 'Two Columns: Top CTA',
    bottom: 'Two Columns: Bottom CTA',
  },
  buttons: {
    blackNoBg: 'Button: Black Text / No Background',
    blueNoBg: 'Button: Blue Text / No Background',
    whiteNoBg: 'Button: White Text / No Backgroud',
    blueWhiteBg: 'Button: Blue Text / White Background',
    whiteBlueBg: 'Button: White Text / Blue Background',
  },
  listOfCards: {
    DetailedCardWithBg: 'Detailed Card with Background',
    CardWithBgImage: 'Card with Background Image',
    Default: 'Default',
    BasicQuote: 'Basic Quote',
  },
};

export default styles;
